import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { FormControlLabel, Checkbox, Avatar, Grid, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import dayjs from 'dayjs'
import { isNil } from 'lodash'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import { Metadata } from '@mth/components/Metadata/Metadata'
import { EMPTY_STRING, WITHDRAWN_VALIDATION_AGE } from '@mth/constants'
import { Paragraph } from '@mth/core/components/shared/Paragraph/Paragraph'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import { MthColor, MthRoute, RoleLabels, StudentStatus, StudentStatusLabel, MthTitle } from '@mth/enums'
import { becomeUserMutation } from '@mth/graphql/mutation/user'
import { Student } from '@mth/models'
import { UserInfo } from '@mth/providers/UserContext/UserProvider'
import GradeComponent from '@mth/screens/Admin/UserProfile/components/Students/GradeComponent'
import { SchoolYearProps } from '@mth/screens/Admin/UserProfile/components/Students/types'
import { getStringInitialLetter } from '@mth/utils'

type StudentsProps = {
  students: Student[]
  selectedStudent: number
  handleChangeStudent: (student: Student) => void
  me: UserInfo
  currentSchoolYear: SchoolYearProps
  nextSchoolYear: SchoolYearProps
  userRole: string
}

export const Students: React.FC<StudentsProps> = ({
  students,
  selectedStudent,
  handleChangeStudent,
  me,
  currentSchoolYear,
  nextSchoolYear,
  userRole,
}) => {
  const history = useHistory()
  const sliderRef = useRef(null)
  const [showAll, setShowAll] = useState(false)
  const status = {
    0: StudentStatusLabel.PENDING,
    1: StudentStatusLabel.ACTIVE,
    2: StudentStatusLabel.WITHDRAWN,
    3: StudentStatusLabel.GRADUATED,
    4: EMPTY_STRING,
    10: StudentStatusLabel.COMPLETED,
  }

  function SampleNextArrow() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
          right: '-160px',
          top: '31%',
          width: '150px',
          height: '30px',
          position: 'absolute',
          alignItems: 'center',
        }}
      >
        <ChevronRightIcon
          style={{ display: 'block', color: 'black', background: '#FAFAFA', cursor: 'pointer' }}
          // @ts-ignore
          onClick={() => sliderRef.current.slickNext()}
        />
      </div>
    )
  }

  function SamplePrevArrow(props: { className?: string }) {
    const { className } = props
    return (
      <ChevronLeftIcon
        className={className}
        style={{ display: 'block', color: 'black', background: '#FAFAFA' }}
        // @ts-ignore
        onClick={() => sliderRef.current.slickPrev()}
      />
    )
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const [becomeUserAction] = useMutation(becomeUserMutation)

  const becomeUser = (id: number) => {
    becomeUserAction({
      variables: {
        userId: Number(id),
      },
    })
      .then((resp) => {
        localStorage.setItem('masquerade', resp.data.masqueradeUser.jwt)
        localStorage.setItem('previousPage', location.href.replace(import.meta.env.VITE_PUBLIC_WEB_URL, ''))
      })
      .then(() => {
        history.push(MthRoute.DASHBOARD)
        location.reload()
      })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '24px',
        }}
      >
        <Subtitle size='large' fontWeight='700' sx={{ marginRight: '36px' }}>
          {MthTitle.STUDENTS}
        </Subtitle>
        <FormControlLabel
          control={<Checkbox value={showAll} onChange={(_e, checked) => setShowAll(checked)} />}
          label={
            <Paragraph fontWeight='700' size='medium'>
              {MthTitle.SHOW_GRADUATED_COMPLETED}
            </Paragraph>
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '24px',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Slider {...settings} ref={sliderRef}>
              {students
                .filter((item) => {
                  const status: StudentStatus | undefined = Number(item.status?.[0]?.status) || undefined
                  let isValidAge = !item?.person?.date_of_birth

                  if (!isValidAge) {
                    const itemDateOfBirth = new Date(item?.person?.date_of_birth)
                    const currentDate = new Date()
                    const validationAge = new Date()
                    validationAge.setFullYear(currentDate.getFullYear() - WITHDRAWN_VALIDATION_AGE)
                    isValidAge = itemDateOfBirth > validationAge
                  }

                  return (
                    [
                      undefined,
                      StudentStatus.DELETED,
                      StudentStatus.PENDING,
                      StudentStatus.ACTIVE,
                      StudentStatus.APPLIED,
                      StudentStatus.REAPPLIED,
                      StudentStatus.ACCEPTED,
                      StudentStatus.INACTIVE,
                    ].includes(status) ||
                    (status === StudentStatus.WITHDRAWN && isValidAge)
                  )
                })
                .sort((a, b) => {
                  const hasNoStatusA =
                    a.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id) === undefined
                  const hasNoStatusB =
                    b.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id) === undefined

                  if (hasNoStatusA && !hasNoStatusB) return 1
                  if (!hasNoStatusA && hasNoStatusB) return -1

                  const isWithdrawnA =
                    a.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id)?.status ===
                    StudentStatus.WITHDRAWN
                  const isWithdrawnB =
                    b.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id)?.status ===
                    StudentStatus.WITHDRAWN

                  if (isWithdrawnA && !isWithdrawnB) return 1
                  if (!isWithdrawnA && isWithdrawnB) return -1

                  const firstNameA = String(a?.person.first_name?.toLowerCase())
                  const firstNameB = String(b?.person.first_name?.toLowerCase())

                  if (firstNameA < firstNameB) return -1
                  if (firstNameA > firstNameB) return 1

                  return a.student_id - b.student_id
                })
                .map((item, idx) => (
                  <Box sx={{ cursor: 'pointer' }} onClick={() => handleChangeStudent(item)} key={idx}>
                    <Metadata
                      title={
                        <Subtitle
                          fontWeight='600'
                          // @ts-ignore
                          color={
                            selectedStudent === Number(item.student_id) && userRole === RoleLabels.STUDENT
                              ? MthColor.MTHBLUE
                              : MthColor.SYSTEM_01
                          }
                        >
                          {item.person.first_name}
                        </Subtitle>
                      }
                      subtitle={
                        <Box display={'flex'} flexDirection='row' alignItems={'center'} alignContent='center'>
                          <Paragraph
                            fontWeight='600'
                            // @ts-ignore
                            color={
                              selectedStudent === Number(item.student_id) && userRole === RoleLabels.STUDENT
                                ? MthColor.MTHBLUE
                                : MthColor.SYSTEM_11
                            }
                            sx={{ fontSize: '16px' }}
                          >
                            <GradeComponent
                              item={item}
                              currentYear={currentSchoolYear}
                              nextYear={nextSchoolYear}
                              isWithdrawn={
                                item.status?.find((item) => item?.school_year_id === currentSchoolYear?.school_year_id)
                                  ?.status === StudentStatus.WITHDRAWN
                              }
                            />
                          </Paragraph>
                          {selectedStudent === Number(item.student_id) &&
                            userRole === RoleLabels.STUDENT &&
                            Boolean(me.masquerade) &&
                            !isNil(item?.person?.user_id) &&
                            !isNaN(Number(item.person.user_id)) && (
                              <Tooltip title='Masquerade'>
                                <AccountBoxOutlinedIcon
                                  sx={{ color: MthColor.MTHBLUE, height: 15, width: 15 }}
                                  onClick={() => becomeUser(Number(item.person.user_id))}
                                />
                              </Tooltip>
                            )}
                        </Box>
                      }
                      image={
                        <Avatar
                          alt={item.person.preferred_first_name ?? item.person.first_name}
                          src={EMPTY_STRING}
                          variant='rounded'
                          style={{ marginRight: 8 }}
                        >
                          {getStringInitialLetter(item.person.preferred_first_name ?? item.person.first_name)}
                        </Avatar>
                      }
                    />
                  </Box>
                ))}

              {students
                .filter((item) => {
                  const studentLatestStatus = item.status[0]?.status || null
                  const studentDateOfBirth = item.person.date_of_birth

                  return (
                    showAll &&
                    (studentLatestStatus === StudentStatus.GRADUATED ||
                      studentLatestStatus === StudentStatus.COMPLETED ||
                      (studentLatestStatus === StudentStatus.WITHDRAWN &&
                        studentDateOfBirth &&
                        dayjs(studentDateOfBirth) < dayjs().subtract(19, 'years')))
                  )
                })
                .map((item, idx) => (
                  <Box sx={{ cursor: 'pointer' }} onClick={() => handleChangeStudent(item)} key={idx}>
                    <Metadata
                      title={
                        <Subtitle
                          fontWeight='700'
                          // @ts-ignore
                          color={selectedStudent === Number(item.student_id) ? MthColor.MTHBLUE : MthColor.GRAY}
                        >
                          {item.person.first_name}
                        </Subtitle>
                      }
                      subtitle={
                        // @ts-ignore
                        <Paragraph color={MthColor.GRAY} size={'large'}>
                          {status[item.status[0].status as keyof typeof status]}
                        </Paragraph>
                      }
                      image={
                        <Avatar
                          alt={item.person.preferred_first_name ?? item.person.first_name}
                          src={EMPTY_STRING}
                          variant='rounded'
                          style={{ marginRight: 8 }}
                        >
                          {getStringInitialLetter(item.person.preferred_first_name ?? item.person.first_name)}
                        </Avatar>
                      }
                    />
                  </Box>
                ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
