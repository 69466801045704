import React, { useEffect, useMemo, useState } from 'react'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormControl, Select, MenuItem, FormHelperText, Divider, IconButton, Tooltip, InputLabel } from '@mui/material'
import { Box } from '@mui/system'
import { useFlag } from '@unleash/proxy-client-react'
import { map } from 'lodash'
import { EPIC_275_BUG_2172 } from '@mth/constants'
import { MthColor, QUESTION_TYPE } from '@mth/enums'
import { dropdownClasses } from './styles'
import { DropDownProps } from './types'

export const DropDown: React.FC<DropDownProps> = ({
  testId,
  dropDownItems,
  placeholder,
  placeholderColor,
  setParentValue,
  labelTop,
  disabled,
  disableColorChangeWhenDisabled = false,
  size,
  defaultValue,
  sx,
  error,
  dropdownColor,
  isAddable,
  auto = true,
  borderNone = false,
  color,
  id,
  labelTopBgColor,
  labelTopColor,
  deselectable,
  name,
  parentValue,
  useParentValue,
}) => {
  const epic275bug2172 = useFlag(EPIC_275_BUG_2172)
  const convertDefaultValue = (val: string | number | undefined): string | number => {
    return !!val || val === 0 ? val : ''
  }

  const [value, setValue] = useState(convertDefaultValue(defaultValue))
  const handleChange = (val: string | QUESTION_TYPE) => {
    const dropDownValidation = setParentValue(val)
    if (dropDownValidation) return
    if (auto) {
      setValue(val)
    }
  }

  const shouldRenderDropdownValue = (dropdownValue: string | number | boolean) =>
    (!!dropdownValue || dropdownValue === 0) && dropdownValue !== ''

  useEffect(() => {
    setValue(convertDefaultValue(defaultValue))
  }, [defaultValue])

  const dropdownValue = useMemo(() => {
    if (useParentValue) {
      return parentValue ?? ''
    }
    return value
  }, [value, parentValue, useParentValue])

  const sortedDropDownItems = useMemo(() => {
    const allValuesAreNumbers = dropDownItems.every((item) => {
      if (typeof item.label === 'string' && item.label.includes('-')) {
        return !isNaN(Number(item.label.slice(0, 4)))
      }
      return false
    })

    if (allValuesAreNumbers && epic275bug2172) {
      return [...dropDownItems].sort((a, b) => {
        const yearA = typeof a.label === 'string' ? Number(a.label.slice(0, 4)) : 0
        const yearB = typeof b.label === 'string' ? Number(b.label.slice(0, 4)) : 0
        return yearB - yearA
      })
    }
    return dropDownItems
  }, [dropDownItems, placeholder])

  let renderDropDownItems = map(sortedDropDownItems, (dropDownItem, index) => (
    <MenuItem
      key={index}
      sx={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'inherit' }}
      value={dropDownItem.value}
      disabled={dropDownItem.disabled === true}
    >
      {dropDownItem.label}
      {dropDownItem.hasDeleteIcon && (
        <Tooltip title='Delete' placement='top'>
          <IconButton
            onClick={() => {
              if (dropDownItem?.handleDeleteItem) dropDownItem?.handleDeleteItem(dropDownItem.value)
            }}
          >
            <DeleteForeverOutlinedIcon sx={{ cursor: 'pointer', width: '25px', height: '25px' }} fontSize='medium' />
          </IconButton>
        </Tooltip>
      )}
    </MenuItem>
  ))

  if (deselectable) {
    renderDropDownItems = [
      ...[
        <MenuItem key={`${name || 'xunknown'}-0`} sx={{ display: 'flex', justifyContent: 'space-between' }} value=''>
          <span style={{ color: 'gray' }}>{'Select'}</span>
        </MenuItem>,
      ],
      ...renderDropDownItems,
    ]
  }

  if (isAddable) {
    renderDropDownItems.push(<Divider key={-1} />)
    renderDropDownItems.push(
      <MenuItem key={-2} value={0}>
        Add...
      </MenuItem>,
    )
  }

  return (
    <Box sx={{ minWidth: 120, zIndex: 1, ...sx }} data-testid={testId} id={id}>
      {!labelTop ? (
        <>
          {borderNone ? (
            <FormControl
              variant='standard'
              fullWidth
              disabled={disabled || false}
              sx={{
                ...(disableColorChangeWhenDisabled
                  ? {
                      '.Mui-disabled': {
                        color: 'initial',
                        '-webkit-text-fill-color': 'initial',
                      },
                    }
                  : null),
              }}
            >
              <Select
                size='small'
                value={dropdownValue}
                IconComponent={(props) => <ExpandMoreIcon style={{ color: color ?? MthColor.SKY }} {...props} />}
                disableUnderline
                // @ts-ignore
                onChange={(e) => handleChange(e.target?.value)}
                displayEmpty
                renderValue={
                  shouldRenderDropdownValue(dropdownValue)
                    ? () => (
                        <span style={{ color, marginRight: '15px' }}>
                          {
                            sortedDropDownItems?.find((item) => item.value.toString() === dropdownValue.toString())
                              ?.label
                          }
                        </span>
                      )
                    : // @ts-ignore
                      () => <span style={{ color: MthColor.BLUE_GRADIENT, marginRight: '15px' }}>{placeholder}</span>
                }
                sx={{ ...dropdownClasses.borderNone }}
                name={name}
              >
                {renderDropDownItems}
              </Select>
              {error?.error && <FormHelperText sx={{ color: MthColor.RED }}>{error?.errorMsg}</FormHelperText>}
            </FormControl>
          ) : (
            <FormControl
              fullWidth
              className='MthFormField'
              sx={{
                ...(disableColorChangeWhenDisabled
                  ? {
                      '.Mui-disabled': {
                        color: 'initial',
                        '-webkit-text-fill-color': 'initial',
                      },
                    }
                  : null),
              }}
            >
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={dropdownValue}
                // @ts-ignore
                onChange={(e) => handleChange(e.target?.value)}
                displayEmpty
                renderValue={
                  shouldRenderDropdownValue(dropdownValue)
                    ? () => (
                        <span>
                          {
                            sortedDropDownItems?.find((item) => item.value.toString() === dropdownValue.toString())
                              ?.label
                          }
                        </span>
                      )
                    : () => <span style={{ color: placeholderColor ?? 'gray' }}>{placeholder}</span>
                }
                sx={{
                  ...sx,
                  '& .MuiSelect-outlined': {
                    background: dropdownColor,
                  },
                }}
                size={size || 'medium'}
                error={error?.error}
                disabled={disabled || false}
                name={name}
              >
                {renderDropDownItems}
              </Select>
              {error?.error && <FormHelperText sx={{ color: MthColor.RED }}>{error?.errorMsg}</FormHelperText>}
            </FormControl>
          )}
        </>
      ) : (
        <FormControl
          fullWidth
          className='MthFormField'
          focused
          error={error?.error}
          sx={{
            ...(disableColorChangeWhenDisabled
              ? {
                  '.Mui-disabled': {
                    color: 'initial',
                    '-webkit-text-fill-color': 'initial',
                  },
                }
              : null),
          }}
        >
          <InputLabel
            sx={{
              backgroundColor: labelTopBgColor ? labelTopBgColor : '#FFF',
              color: labelTopColor ? `${labelTopColor} !important` : MthColor.BLACK,
              paddingX: 1,
            }}
          >
            {placeholder}
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={dropdownValue}
            // @ts-ignore
            onChange={(e) => handleChange(e.target?.value)}
            displayEmpty
            renderValue={
              shouldRenderDropdownValue(dropdownValue)
                ? () => (
                    <span
                      style={{
                        color:
                          sortedDropDownItems?.find((item) => item.value.toString() === dropdownValue.toString())
                            ?.color ?? MthColor.BLACK,
                      }}
                    >
                      {sortedDropDownItems?.find((item) => item.value.toString() === dropdownValue.toString())?.label}
                    </span>
                  )
                : () => <span style={{ color: 'gray' }}>{'Select'}</span>
            }
            sx={{
              ...sx,
              '& .MuiSelect-outlined': {
                background: dropdownColor,
              },
            }}
            size={size || 'medium'}
            error={error?.error}
            disabled={disabled || false}
            name={name}
          >
            {renderDropDownItems}
          </Select>
          {error?.error && <FormHelperText sx={{ color: MthColor.RED }}>{error?.errorMsg}</FormHelperText>}
        </FormControl>
      )}
    </Box>
  )
}
